import React from "react"
import SEO from "@components/seo"
import * as data from "@data/pledge"
import HeaderandText from "@components/header_with_text"
import Layout from "@components/layout"
import BackButton from "@components/back_button"
import LoginForm from "@components/_molecules/form_login"
import * as beneficiaryToken from "@services/beneficiaryToken"

beneficiaryToken.setToken()

const LoginPage = () => {
  const { login } = data
  return (
    <Layout type="light" pageClass="pledge">
      <BackButton data={login.backLink} backLinkNative={login.backLinkNative} />
      <SEO title={login.title} />
      <HeaderandText data={login.heading} />
      <LoginForm />
    </Layout>
  )
}

export default LoginPage
