import React, { Component } from "react"
import "@components/_molecules/input_label_state/style.scss"

class InputPassword extends Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false
    }
  }
  showHidePassword = () => {
    var input = document.getElementById("password")
    if (input.type === "password") {
      input.type = "text"
      this.setState({ show: true })
    } else {
      input.type = "password"
      this.setState({ show: false })
    }
  }
  render() {
    const { data } = this.props
    return (
      <div className="input-label-state-grouping">
        <label
          htmlFor={data.name.replace(/ /g, "").toLowerCase()}
          className={data.value !== "" ? `has-text` : ``}
        >
          {data.name}
        </label>
        <input
          name={data.name.replace(/ /g, "").toLowerCase()}
          type="password"
          value={data.value}
          onChange={event => this.props.onChange(event)}
          id="password"
        />
        <p
          className={`input-help${data.errorStatus ? ` is-error` : ``}`}
          inputstate={this.state.inputState}
          style={{
            opacity: data.defaultVisibility || data.errorStatus ? 1 : 0
          }}
        >
          {data.helpText}
        </p>
        <span
          className={
            !this.state.show
              ? "show-hide-toggle show-text"
              : "show-hide-toggle hide-text"
          }
          onClick={this.showHidePassword}
        ></span>
      </div>
    )
  }
}

export default InputPassword
