import React, { Component } from "react"
import "@components/form_parent_registration/style.scss"
import { Link } from "gatsby"
import axios from "axios"
import * as headers from "@data/headers"
import FormButton from "@components/_atoms/button_form_input_submit"
import InputLabelState from "@components/_molecules/input_label_state"
import GeneralSubmitMessage from "@components/_atoms/general_submit_message"
import * as data from "@data/pledge"
import { validate } from "@data/validations"
import * as errors from "@services/errors"
import LAEJS from "@services/LAEJS"
import { URL_PARENT_DASHBOARD } from "@data/constants"
import InputPassword from "@components/_molecules/input_password"

class LoginForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: "",
      emailErrorText: "Email already exists",
      emailErrorStatus: false,
      password: "",
      passwordErrorText: "Incorrect Password",
      passwordErrorStatus: false,
      buttonSubmitted: false,
    }
  }

  componentDidMount = () => {
    const header_values = headers.getHeaders()
    if (typeof window !== "undefined" && header_values.AuthorizationToken) {
      window.localStorage.clear()
    }
  }

  handleChange = (ev) => {
    this.setState({
      [ev.target.name]: ev.target.value,
      [`${ev.target.name}ErrorStatus`]: false,
    })
  }

  handleSubmit = (ev) => {
    ev.preventDefault()
    let _this = this //needed to avoid losing context during axios/fetch call
    let emailErrorStatus = !validate.email.test(this.state.email),
      passwordErrorStatus = !validate.password.test(this.state.password)
    _this.setState({ emailErrorStatus, passwordErrorStatus })
    if (!emailErrorStatus && !passwordErrorStatus) {
      _this.setState({ buttonSubmitted: true })
      let authenticationQuery = {
        query: `mutation($createSessionTokenInput: CreateSessionTokenInput!) {
          createSessionToken(input: $createSessionTokenInput) {
            session {
              token
            }
          }
        }`,
        variables: {
          createSessionTokenInput: {
            user: {
              email: _this.state.email,
              password: _this.state.password,
            },
          },
        },
      }

      const options = {
        method: "POST",
        data: JSON.stringify(authenticationQuery),
        headers: headers.getHeaders(),
        url: headers.api,
      }
      axios(options)
        .then((res) => {
          if (res.data.errors) {
            _this.errorHandler(res.data.errors[0].message)
          } else {
            if (typeof window !== `undefined`)
              window.localStorage.setItem(
                `elsi`,
                res.data.data.createSessionToken.session.token
              )
            localStorage.setItem("parentEmail", _this.state.email)
            LAEJS.jsSetUserIsCustodian({
              done: () => {
                window.location.href = URL_PARENT_DASHBOARD
              },
            })
          }
        })
        .catch((error) => {
          this.setState({ buttonSubmitted: false })
          console.log(error)
        })
    }
  }

  errorHandler = (err) => {
    this.setState({
      buttonSubmitted: false,
      generalError: errors.messaging(err),
    })
  }

  render() {
    const { parentReg } = data
    return (
      <div className="registration-form">
        <form onSubmit={this.handleSubmit}>
          <InputLabelState
            data={{
              name: `Email`,
              type: `email`,
              helpText: this.state.emailErrorText,
              errorStatus: this.state.emailErrorStatus,
              defaultVisibility: 0,
              value: this.state.email,
            }}
            onChange={this.handleChange.bind(this)}
          />
          <InputPassword
            data={{
              name: `Password`,
              helpText: this.state.passwordErrorText,
              errorStatus: this.state.passwordErrorStatus,
              defaultVisibility: 0,
              value: this.state.password,
            }}
            onChange={this.handleChange.bind(this)}
          />
          <div style={{ width: `100%`, height: `3rem` }}></div>
          <GeneralSubmitMessage error={this.state.generalError} />
          <FormButton
            data={parentReg.regButton}
            isDisabled={this.state.buttonSubmitted}
          />
        </form>
        <div style={{ width: `100%`, textAlign: `center`, minHeight: `44px` }}>
          <Link to="/forgot-password" style={{ color: `#00b983` }}>
            Forgot Password?
          </Link>
        </div>
      </div>
    )
  }
}

export default LoginForm
